import React from 'react';
import {  Layout } from 'antd';
import UploadForm from './UploadForm';
const { Header, Content, Footer } = Layout;

const App = () => {
  return (
    <Layout className="layout" style={{ minHeight: '100vh' }}>
      <Header style={{ textAlign: 'center', paddingBottom:100 }}  >
        <h1 style={{ color: 'white' }}>Image to PDF Processor</h1>
      </Header>
      <Content
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '50px',
        }}
      >
        
        <div className="site-layout-content" style={{ width: '100%', maxWidth: '500px' }}>
          <UploadForm />
        </div>
      </Content>
      <Footer style={{ textAlign: 'center' }}>Image to PDF Processor ©2024</Footer>
    </Layout>
  );
};

export default App;
