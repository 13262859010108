import React, { useState } from 'react';
import { Form, Button, Upload, Radio, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import axios from 'axios';

const UploadForm = () => {
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [downloadUrls, setDownloadUrls] = useState({ a4pdf: '', a5pdf: '' });

  let prod = 'https://test.jbsurja.com';
  let dev = 'http://localhost:5000';

  const handleUpload = ({ fileList }) => setFileList(fileList);

  const handleSubmit = async (values) => {
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append('images', file.originFileObj);
    });
    setLoading(true);

    try {
      const response = await axios.post(`${prod}/upload`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      setLoading(false);
      message.success('Images uploaded and processed successfully!');
      setDownloadUrls(response.data);
      setFileList([]); // Clear the fileList after success
    } catch (error) {
      setLoading(false);
      message.error('Error processing images!');
      console.error(error);
    }
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleSubmit}
      style={{ 
        maxWidth: '400px', 
        margin: '0 auto', 
        textAlign: 'center',
        border: '1px solid #d9d9d9', 
        padding: '20px', 
        borderRadius: '8px', 
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)' 
      }}
    >
      <Form.Item
        name="images"
        label="Upload Images"
        rules={[{ required: true, message: 'Please upload two images!' }]}
      >
        <Upload
          name="images"
          beforeUpload={() => false}
          fileList={fileList}
          onChange={handleUpload}
          multiple
          maxCount={2}
        >
          <Button icon={<UploadOutlined />}>Select Images</Button>
        </Upload>
      </Form.Item>
      
      <Form.Item>
        <Button type="primary" htmlType="submit" disabled={loading} loading={loading}>
          Submit
        </Button>
      </Form.Item>
      {downloadUrls.a4pdf && (
        <div style={{ marginTop: '20px' }}>
          <Button type="link" href={`${prod}${downloadUrls.a4pdf}`} target="_blank">
            Download A4 PDF
          </Button>
          <Button type="link" href={`${prod}${downloadUrls.a5pdf}`} target="_blank">
            Download A5 PDF
          </Button>
        </div>
      )}
    </Form>
  );
};

export default UploadForm;
